import React from "react";
import PropTypes from "prop-types";
import Colors from "../utils/Colors";
import "../styles/logo.css";

export default class Logo extends React.Component {
  render() {
    return (
      <div style={{marginLeft: this.props.left}}>
        <h1 className="logo">
          <font color={Colors.IdentityGreen}>cotilla</font>
          <font color="#ffffff">.</font>
          <font className="logoDevPortion" color={Colors.IdentityPink}>dev</font>
        </h1>
      </div>
    );
  }
}

Logo.propTypes = {
  left: PropTypes.string
};