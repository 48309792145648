import React from "react";
import Colors from "./utils/Colors";
import {CommonMargin} from "./utils/Constants";
import Logo from "./components/Logo";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "./styles/navbar.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

// Website global background color.
document.body.style.background = Colors.DarkBackground;

const NavItemMap = {
  about: {
    path: "#about",
    name: ".about"
  },
  work: {
    path: "#work",
    name: ".work"
  },
  articles: {
    path: "#articles",
    name: ".articles"
  },
  contact: {
    path: "#contact",
    name: ".contact"
  }
};

const MenuImage = {
  open: "bi bi-list",
  close: "bi bi-x-lg"
};

export default class App extends React.Component {
  constructor(props) {
    super(props);

    // Binding.
    this.onNavItemSelected = this.onNavItemSelected.bind(this);
    this.onMenuToggled = this.onMenuToggled.bind(this);
    this.findActiveNavItem = this.findActiveNavItem.bind(this);
    this.classForNavItem = this.classForNavItem.bind(this);
    this.isNavigationPathValid = this.isNavigationPathValid.bind(this);

    // Setup "class" properties.
    this.state = {
      isMenuOpen: false,
      menuImg: MenuImage.open,
      activeNavItem: this.findActiveNavItem()
    };
  }

  render() {
    const {isMenuOpen, menuImg, activeNavItem} = this.state;

    return (
      <Navbar className="navbarWithShadow" bg="transparent" variant="dark" sticky="top" expand="sm" collapseOnSelect onToggle={this.onMenuToggled}>
        <Navbar.Brand>
          <Logo left={CommonMargin.left}/>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" className="justify-content-end" style={{marginRight: CommonMargin.right}}>
          <i className={menuImg}></i>
        </Navbar.Toggle>
        <Navbar.Collapse className="justify-content-end" style={{marginRight: CommonMargin.right}}>
          <Nav className="navItemContainer" style={{marginLeft: CommonMargin.left}} defaultActiveKey={this.findActiveNavItem().path} onSelect={this.onNavItemSelected}>
            <Nav.Link className={this.classForNavItem(NavItemMap.about, activeNavItem, isMenuOpen)} href={NavItemMap.about.path}>{NavItemMap.about.name}</Nav.Link>
            <Nav.Link className={this.classForNavItem(NavItemMap.work, activeNavItem, isMenuOpen)} href={NavItemMap.work.path}>{NavItemMap.work.name}</Nav.Link>
            <Nav.Link className={this.classForNavItem(NavItemMap.articles, activeNavItem, isMenuOpen)} href={NavItemMap.articles.path}>{NavItemMap.articles.name}</Nav.Link>
            <Nav.Link className={this.classForNavItem(NavItemMap.contact, activeNavItem, isMenuOpen)} href={NavItemMap.contact.path}>{NavItemMap.contact.name}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  //
  // ANCHOR: Navigation Utils
  //

  classForNavItem(navItem, activeNavItem, isMenuOpen) {
    // Display the underline only if showing the nav items in tab form.
    if (!isMenuOpen) {
      return navItem.path === activeNavItem.path ? "navItemActive" : "navItem";
    }

    return "navItem";
  }

  findActiveNavItem() {
    const locationUrl = window.location.href;
    const navItemPath = locationUrl.split("/").pop();

    // Default to "/home" nav item.
    let navItem = NavItemMap.about;

    if (navItemPath === NavItemMap.about.path) {
      navItem = NavItemMap.about;
    }
    else if (navItemPath === NavItemMap.work.path) {
      navItem = NavItemMap.work;
    }
    else if (navItemPath === NavItemMap.articles.path) {
      navItem = NavItemMap.articles;
    }
    else if (navItemPath === NavItemMap.contact.path) {
      navItem = NavItemMap.contact;
    }

    return navItem;
  }

  isNavigationPathValid(path) {
    return path === NavItemMap.about.path || path === NavItemMap.work.path || path === NavItemMap.articles.path || path === NavItemMap.contact.path;
  }

  //
  // ANCHOR: Navigation Callbacks
  //

  onNavItemSelected(path) {
    if (path === NavItemMap.about.path) {
      this.setState({activeNavItem: NavItemMap.about});
      console.log("Scroll to about");
    }
    else if (path === NavItemMap.work.path) {
      this.setState({activeNavItem: NavItemMap.work});
      console.log("Scroll to work");
    }
    else if (path === NavItemMap.articles.path) {
      this.setState({activeNavItem: NavItemMap.articles});
      console.log("Scroll to articles");
    }
    else if (path === NavItemMap.contact.path) {
      this.setState({activeNavItem: NavItemMap.contact});
      console.log("Scroll to contact");
    }
  }

  onMenuToggled(isMenuOpen) {
    this.setState({
      menuImg: isMenuOpen ? MenuImage.close : MenuImage.open,
      isMenuOpen: isMenuOpen
    });
  }
}